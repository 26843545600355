import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import axios from 'axios';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import './App.css';
import Login from './login'; // Corrected import statement
import ThumbsUpIcon from './assets/thumbs-up-icon.svg'; // Adjust path as necessary
import ThumbsDownIcon from './assets/thumbs-down-icon.svg';
import ThumbsUpFilledIcon from './assets/thumbs-up-filled-icon.svg'; // Add filled icons
import ThumbsDownFilledIcon from './assets/thumbs-down-filled-icon.svg';

const socket = io("https://api.cbanc-bank-ai.com", {
  withCredentials: true,
  extraHeaders: {
    "Access-Control-Allow-Origin": "https://https://app.cbanc-bank-ai.com"
  }
});
function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState('');
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [typingMessage, setTypingMessage] = useState('');
  const [sessionId, setSessionId] = useState(null);
  const [userDetails, setUserDetails] = useState({ firstName: '', lastName: '' });
  const [chatSessions, setChatSessions] = useState([]);
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false);
  const messagesEndRef = useRef(null);
  const [userId, setUserId] = useState(null);
  const [showFeedbackBoxFor, setShowFeedbackBoxFor] = useState(null);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [feedbackSubmittedFor, setFeedbackSubmittedFor] = useState(new Set());
  const [thumbsUpSet, setThumbsUpSet] = useState(new Set());
  const [thumbsDownSet, setThumbsDownSet] = useState(new Set());

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    setToken('');
    setUserId(null);
    setIsAuthenticated(false);
  };

  const handleThumbsDown = async (inputResponseId) => {
    setShowFeedbackBoxFor(inputResponseId); // Show feedback box
  };
  const handleThumbsUp = async (inputResponseId) => {
    try {
      await axios.post('https://api.cbanc-bank-ai.com/thumbs-up', { inputResponseId });
      setThumbsUpSet(new Set([...thumbsUpSet, inputResponseId]));
      setThumbsDownSet(new Set([...thumbsDownSet].filter(id => id !== inputResponseId)));
      setShowFeedbackBoxFor(null); // Hide feedback box if visible
    } catch (error) {
      console.error('Error on thumbs-up:', error);
    }
  };
  
  const submitFeedback = async (inputResponseId) => {
    try {
      await axios.post('https://api.cbanc-bank-ai.com/thumbs-down', { inputResponseId, feedback: feedbackMessage });
      setThumbsDownSet(new Set([...thumbsDownSet, inputResponseId]));
      setThumbsUpSet(new Set([...thumbsUpSet].filter(id => id !== inputResponseId)));
      setShowFeedbackBoxFor(null);
      setFeedbackMessage(''); // Clear feedback message
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  };
  const cancelFeedback = () => {
    setShowFeedbackBoxFor(null);
    setFeedbackMessage(''); // Clear the feedback message field
  };
  const showFeedbackBox = (inputResponseId) => {
    setShowFeedbackBoxFor(inputResponseId);
    setFeedbackMessage(''); // Reset any existing feedback message
  };
  
  const handleLogin = (token, userId) => {
    localStorage.setItem('token', token);
    localStorage.setItem('userId', userId);
    setToken(token);
    setUserId(userId);
    setIsAuthenticated(true);
  };
  
  const startNewSession = async () => {
    setMessages([]); // Clear existing messages
    setTypingMessage(''); // Also clear any partially received message
    setSessionId(null); // Reset session ID (if needed, depends on your backend)
    setInput(''); // Clear the input field
  }  
  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const storedUserId = localStorage.getItem('userId');
  
    if (storedToken && storedUserId) {
      setToken(storedToken);
      setUserId(storedUserId);
      setIsAuthenticated(true);
    }
  }, []);
  useEffect(() => {
    // Fetch user details
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get('https://api.cbanc-bank-ai.com/get_user_details', { params: { user_id: userId } });
        const { first_name, last_name } = response.data.user_details;
        setUserDetails({ firstName: first_name, lastName: last_name });
        console.log(response.data.user_details)
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    if (isAuthenticated && userId) {
      fetchUserDetails();
    }
  }, [isAuthenticated, userId]); 
  useEffect(() => {
    const fetchChatSessions = async () => {
      try {
        const response = await axios.get('https://api.cbanc-bank-ai.com/get_chat_sessions', { params: { user_id: userId } });
        setChatSessions(response.data.chat_sessions);
        console.log(response.data.chat_sessions);
      } catch (error) {
        console.error('Error fetching chat sessions:', error);
      }
    };
  
    if (isAuthenticated && userId) {
      fetchChatSessions();
    }
  }, [isAuthenticated, userId]); 

  const fetchChatDetails = async (sessionId) => {
    try {
      const response = await axios.get('https://api.cbanc-bank-ai.com/get_chat_details', { params: { session_id: sessionId } });
      const chatDetails = response.data.chat_details;

      const newThumbsUpSet = new Set();
      const newThumbsDownSet = new Set();

      chatDetails.forEach(msg => {
        if (msg.sender === 'response') {
          if (msg.thumbs_up) {
            newThumbsUpSet.add(msg.id);
          }
          if (msg.feedback) {
            newThumbsDownSet.add(msg.id);
          }
        }
      });

      setThumbsUpSet(newThumbsUpSet);
      setThumbsDownSet(newThumbsDownSet);

      // Directly set the fetched chat details to the messages state
      setMessages(chatDetails);
      setSessionId(sessionId);
    } catch (error) {
      console.error('Error fetching chat details:', error);
    }
  };
  useEffect(() => {
    const handleResponsePart = (messagePart) => {
      if (messagePart.session_id === sessionId) {
        setTypingMessage(prevTypingMsg => prevTypingMsg + messagePart.response_part);
      }
    };
  
    const handleResponseComplete = (message) => {
      if (message.session_id === sessionId) {
        setMessages(prevMsgs => [...prevMsgs, { 
          text: typingMessage, 
          sender: 'response', 
          inputResponseId: message.inputResponseId 
        }]);
        setTypingMessage('');
        setIsAwaitingResponse(false);
      }
    };
  
    socket.on('chat_response_part', handleResponsePart);
    socket.on('chat_response_complete', handleResponseComplete);
  
    // Cleanup
    return () => {
      socket.off('chat_response_part', handleResponsePart);
      socket.off('chat_response_complete', handleResponseComplete);
    };
  }, [sessionId, typingMessage, isAuthenticated]);
  
const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Effect to scroll to the bottom whenever messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  const sendMessage = async () => {
    if (input.trim() && !isAwaitingResponse) {
      setIsAwaitingResponse(true);
      let usedSessionId = sessionId;
      console.log(usedSessionId)
      console.log(messages.length)
  
      // If there's no session ID and no messages, create a new session
      if (!usedSessionId && messages.length === 0) {
        try {
          console.log(input)
          const response = await axios.post('https://api.cbanc-bank-ai.com/new_session',  { user_id: userId, text_input: input });
          usedSessionId = response.data.session_id; // Immediately use the new session ID
          setSessionId(usedSessionId); // Also update the state for future use
        } catch (error) {
          console.error('Error initializing new chat session:', error);
          return;
        }
      }
  
      setMessages(msgs => [...msgs, { text: input, sender: 'user' }]);
      socket.emit('chat_message', { session_id: usedSessionId, message: input });
      setInput('');
    }
  };
  

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  };

  const renderMarkdown = (text) => {
    return DOMPurify.sanitize(marked(text));
  };
  const createInitials = (firstName, lastName) => {
    const firstInitial = firstName?.charAt(0) || '';
    const lastInitial = lastName?.charAt(0) || '';
    return `${firstInitial}${lastInitial}`.toUpperCase();
};
if (!isAuthenticated) {
return <Login onLogin={handleLogin} />;
}
  return (
    <div className="app-container">
      <div className="sidebar">
        <div className='sidebar-header'>
          <img src='bankai-icon.png' alt="" className="message-icon" />
          <p className='new-chat-text' onClick={startNewSession}>New Chat</p>
          <img src='edit.png' alt="" className="edit-chat-icon" onClick={startNewSession} />
        </div>
        <div className='date-side'>
          <p className='date-side-text'>Today</p>
          {chatSessions.map((session, index) => (
          <div key={index} className='date-side-item' onClick={() => fetchChatDetails(session.id)}>
            <p className='side-chat-name'>{session.title}</p>
          </div>
        ))}
        </div>
        <div className='lower-side'>
          <div className='upgrade-side'>
          <img src='upgrade-icon.png' alt="" className="message-icon" />
          <a href='https://meetings.hubspot.com/connor-huddleston/enterprise-demo'>
          <p className='upgrade-tite'>Demo Enterprise</p>
          <p className='upgrade-desc'>Tailored AI Assistant for your Bank</p>
          </a>
          </div>
          <div className='user-profile'>
              <div className='profile-circle' style={{ backgroundColor: '#f43d3d' }}>
                <p className='circle-initials'>{createInitials(userDetails.firstName, userDetails.lastName)}</p>
              </div>
              <p className='user-tite'>{`${userDetails.firstName} ${userDetails.lastName}`}</p>
          </div>
        </div>
      </div>
      <div className="chat-container">
      <div className="sub-level">
        <div className="sub-level-content"> {/* Container for existing sub-level content */}
          <img className='main-logo' src='bankai.png'/>
          <div className='tier-tag'>
              <p className='tag-text'>Beta</p>
          </div>
        </div>
        <button onClick={handleLogout} className="logout-button">Logout</button> {/* Logout button */}
      </div>
      <div className="messages">
  {messages.map((msg, index) => (
    <div key={index} className={`message ${msg.sender}`}>
      <div className="message-header">
        {msg.sender === 'user' ? (
          <div className='profile-circle' style={{ backgroundColor: '#f43d3d' }}>
            <p className='circle-initials'>
              {createInitials(userDetails.firstName, userDetails.lastName)}
            </p>
          </div>
        ) : (
          <img src='bankai-icon.png' alt="" className="message-icon" />
        )}
        <span className="message-label">{msg.sender === 'user' ? 'You' : 'Hapax AI'}</span>
      </div>
      <div className="message-text" dangerouslySetInnerHTML={{ __html: renderMarkdown(msg.text) }} />
      {msg.sender === 'response' && (
        <div className='feedback-area'>
          <div className="response-icons">
            <div onClick={() => handleThumbsUp(msg.inputResponseId)}>
              <img src={thumbsUpSet.has(msg.inputResponseId) ? ThumbsUpFilledIcon : ThumbsUpIcon} alt="Thumbs Up" className="icon-class" />
            </div>
            <div onClick={() => setShowFeedbackBoxFor(msg.inputResponseId)}>
              <img src={thumbsDownSet.has(msg.inputResponseId) ? ThumbsDownFilledIcon : ThumbsDownIcon} alt="Thumbs Down" className="icon-class" />
            </div>
          </div>
          {showFeedbackBoxFor === msg.inputResponseId && (
            <div className="feedback-box">
              <textarea
                className='feedback-input'
                value={feedbackMessage}
                onChange={(e) => setFeedbackMessage(e.target.value)}
                placeholder="What is wrong with the result?"
                autoFocus
              />
              <div className='feedback-buttons'>
                <button className='feedback-cancel' onClick={() => setShowFeedbackBoxFor(null)}>Cancel</button>
                <button className='feedback-submit' onClick={() => submitFeedback(msg.inputResponseId)}>Submit</button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  ))}
  {typingMessage && (
    <div className="message response">
      <div className="message-header">
        <img src='bankai-icon.png' alt="" className="message-icon" />
        <span className="message-label">Hapax AI</span>
      </div>
      <div className="message-text">{typingMessage}</div>
    </div>
  )}
  <div ref={messagesEndRef} />
</div>
<div className="message-input">
      <input
        type="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder="Type here..."
        onKeyPress={handleKeyPress}
        disabled={isAwaitingResponse} // Disable input when awaiting response
      />
      <button 
        onClick={sendMessage} 
        className="send-button" 
        disabled={isAwaitingResponse} // Disable button when awaiting response
      >
        <img src="Vector.png" alt="Send" />
      </button>
    </div>
      </div>
    </div>
  );
}
export default App;
