import React, { useState } from 'react';
import axios from 'axios';

function Login({ onLogin }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [userId, setUserId] = useState(null);
  const [token, setToken] = useState('');

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://api.cbanc-bank-ai.com/login', { email, password });
      const { access_token, user_id, reset_password } = response.data;
      if (reset_password) {
        setResetPassword(true);
        setUserId(user_id);
        setToken(access_token);
      } else {
        onLogin(access_token, user_id);
      }
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      alert("Passwords do not match.");
      return;
    }
    try {
      await axios.post('https://api.cbanc-bank-ai.com/change_password', {
        user_id: userId,
        new_password: newPassword
      }, {
        headers: {
          Authorization: `Bearer ${token}`  // Include the token in the header
        }
      });
      alert("Password changed successfully");
      onLogin(token, userId);
    } catch (error) {
      console.error('Password change failed:', error);
    }
  };
  return (
    <div className="app-container-login">
      <img src="bankai.png" alt="Logo" className="top-left-logo" />
      
      <div className="content-area">
        <div className="initial-view">
          {!resetPassword && !showLoginForm && (
            <div>
              <img src="login-logo.png" alt="Logo" className="initial-center-logo" />
              <h2 className='login-text'>Get Started</h2>
              <button className='initial-login-button' onClick={() => setShowLoginForm(true)}>Log In</button>
            </div>
          )}
          {!resetPassword && showLoginForm && (
            <div>
              <img src="cancel.png" alt="Back" onClick={() => setShowLoginForm(false)} className="back-arrow" />
              <h2 className='login-text'>Log In</h2>
              <form onSubmit={handleLoginSubmit}>
                <label className='login-label' htmlFor="email">Email Address</label>
                <input className='login-email'
                  id="email"
                  type="text"
                  placeholder='Email Address'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <label className='login-label' htmlFor="password">Password</label>
                <input className='login-pass'
                  id="password"
                  type="password"
                  placeholder='Password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <button type="submit" className="login-button">Log In</button>
              </form>
            </div>
          )}
          {resetPassword && (
            <div className="password-reset-form">
              <h2 className='login-text'>Change Password</h2>
              <form onSubmit={handleChangePassword}>
                <label className='login-label' htmlFor="newPassword">New Password</label>
                <input
                  className='login-pass'
                  type="password"
                  id="newPassword"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
                <label className='login-label' htmlFor="confirmPassword">Confirm Password</label>
                <input
                  className='login-pass'
                  type="password"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                <button type="submit" className="login-button">Change Password</button>
              </form>
            </div>
          )}
        </div>
      </div>
  
      <footer className='login-footer'>
        <img src="bankai.png" alt="Footer Logo" className="footer-logo" />
        <div className="footer-links">
          <span>Terms of Service</span>
          <span className="separator">|</span>
          <span>Privacy Policy</span>
        </div>
      </footer>
    </div>
  );
}

export default Login;
